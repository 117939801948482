@tailwind base;
@tailwind components;
@tailwind utilities;
@layer base {
  img {
    display: initial;
  }
}

/* Hide indicators only for this specific carousel */
.custom-carousel .carousel-indicators {
  display: none !important;
}