@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700&display=swap");

.container {
  max-width: 1100px;
  margin: 0 auto;
  padding: 0 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
}

.navbar {
  height: 100px;
  background-color: #f4f4f4;
  position: relative; /* Keep navbar fixed at the top */
  width: 100%; /* Make it full width */
}

@media (max-width: 955px) {
  .logo {
    width: 200px;
  }
}

@media (max-width: 320px) {
  .logo {
    width: 200px;
  }
}

.menu-icon {
  display: none;
}

.nav-elements ul {
  display: flex;
  justify-content: center; /* Change this to center the menu items */
  align-items: center; /* Change this to vertically center the menu items */
  list-style-type: none;
  color: #1c2541;
}

.nav-elements ul li {
  margin-right: 60px;
  font-size: 20px; /* Change this to increase the font size */
  color: #1c2541;
}

.nav-elements ul a {
  font-size: 20px; /* Change this to increase the font size */
  font-weight: 400;
  color: #1c2541;
  text-decoration: none;
}

.nav-elements ul a.active {
  color: #574c4c;
  font-weight: 500;
  position: relative;
}

.nav-elements ul a.active::after {
  content: "";
  position: absolute;
  bottom: -4px;
  left: 0;
  width: 100%;
  height: 2px;
  background-color: #574c4c;
}

@media (max-width: 1200px) {
  .nav-elements li:last-child {
    margin-right: 60px;
  }
}

@media (max-width: 980px) {
  .menu-icon {
    display: block;
    cursor: pointer;
    margin-right: 20px;
  }

  .nav-elements {
    position: absolute;
    right: 0;
    top: 60px;
    background-color: #f4f4f4;
    width: 0px;
    height: calc(100vh - 60px);
    transition: all 0.3s ease-in;
    overflow: hidden;
  }

  .nav-elements.active {
    width: 270px;
    z-index: 1;
  }

  .nav-elements ul {
    display: flex;
    flex-direction: column;
  }

  .nav-elements ul li {
    margin-right: unset;
    margin-top: 22px;
  }

  /* Custom Select Dropdown */
  .custom-select {
    position: relative;
    display: inline-block;
    cursor: pointer;
    width: 120px; /* Adjust width as needed */
    font-size: 14px;
  }

  .selected-option {
    display: flex;
    align-items: center;
    padding: 5px 10px;
    background-color: #f4f4f4;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 14px;
  }

  .selected-option .flag-icon {
    width: 14px; /* Adjust width for smaller flags */
    height: 10px; /* Adjust height for smaller flags */
    margin-right: 6px; /* Reduce spacing between icon and label */
  }

  .options {
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 1000;
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 4px;
    list-style: none;
    padding: 0;
    margin: 0;
    width: 100%;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    max-height: 150px; /* Limit height for long lists */
    overflow-y: auto; /* Enable scrolling for long lists */
  }

  .option {
    display: flex;
    align-items: center;
    padding: 5px 10px;
    cursor: pointer;
    font-size: 14px;
  }

  .option:hover {
    background-color: #f4f4f4;
  }

  .option .flag-icon {
    width: 14px; /* Match size with selected-option */
    height: 10px;
    margin-right: 6px;
  }
}
